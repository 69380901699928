<template>
  <div v-if="route.query.to !== undefined">Введите телефон для расчета и оформления заказа</div>
  <template v-if="!store.state.auth.isAuthenticated">
    <input class="input-base" style="margin-top: 20px;" v-model="phoneInput" placeholder="Телефон" @keypress.enter="sendPass((changeCommunicationType ? communicationType.SMS : communicationType.WhatsApp))">
    <span class="span-base" v-if="phoneError" style="color: red;">Некорректный номер телефона</span>
    <span class="span-base" v-if="authError" style="color: red;">Ошибка запроса кода, попробуйте запросить позже</span>
    <button class="button-base" v-show="!changeCommunicationType && !passSent" @click="sendPass(communicationType.WhatsApp)">Отправить код по WhatsApp</button>
    <template v-if="changeCommunicationType">
      <!-- <button class="button-base" v-show="!passSent" @click="sendPass(client_driven_msg.CommunicationType.Telegram)">Отправить код в Telegram</button> -->
      <button class="button-base" v-show="!passSent" @click="sendPass(communicationType.SMS)">Отправить код по SMS</button>
    </template>
    <Checkbox v-show="!passSent" v-model:checked="changeCommunicationType">У меня нет WhatsApp</Checkbox>
    <span class="span-base" v-show="passSent">
      {{(authError ? '' : (authBan ? 'Превышено количество запросов.':'Код отправлен.')) +
      ' Еще раз отправить можно будет ' + getDate(sendPassUnlockTime, true, true) }}
    </span>
    <template v-if="passSent && !authBan && !authError">
      <input class="input-base" v-model="passInput" ref="codeInput" placeholder="Код" @keypress.enter="login()">
      <button class="button-base" @click="login()">Войти</button>
      <span class="span-base" v-show="wrongPass" style="color: red;">Неверный код</span>
    </template>
    <div class="login-logo">
      <img src="../../global_assets/img/nav-logo.svg">
    </div>
  </template>
</template>

<script setup lang="ts">
import { ref, inject, nextTick } from 'vue';

import { useStore } from '@/rpc_client/useStore';
import { RpcClient, returnFalse } from '@/rpc_client/apiAbstract';

import { extractPhoneRu, getDate } from '@/rpc_client/misc';
import { client_driven_msg } from '@/rpc_client/messages';
import Checkbox from '@/rpc_client/service_components/Checkbox.vue';
import { useRoute } from 'vue-router';
import Long from 'long';

const route = useRoute()

const rpcClient = inject('RpcClient') as RpcClient;
const communicationType = client_driven_msg.CommunicationType;

const store = useStore();
const phoneInput = ref('');
if (process.env.NODE_ENV !== 'production') {
  phoneInput.value = store.state.custom.appName === 'marketplace' ? '79999999992' : '79999999990';
}
const passInput = ref('');

const wrongPass = ref(false);
const passSent = ref(false);
const authBan = ref(false);
const codeInput = ref<HTMLInputElement | null>(null);
const defaultUnlockTimeout = 30000; // 30 secs timeout
const sendPassUnlockTime = ref(Long.UZERO);

const changeCommunicationType = ref(false);

const phoneError = ref(false);
const authError = ref(false);
async function sendPass (communicationType: client_driven_msg.CommunicationType) {
  phoneError.value = false;
  authError.value = false
  authBan.value = false;
  sendPassUnlockTime.value = Long.UZERO;
  let phone = extractPhoneRu(phoneInput.value);
  if (phone.length === 0) { phoneError.value = true; return }
  let timeout = await rpcClient.auth(phone, communicationType).catch(() => Long.fromNumber(-3));
  let jsTimeout = timeout.toNumber();
  if (jsTimeout > 0) {
    sendPassUnlockTime.value = timeout;
    authBan.value = true;
  } else {
    if (jsTimeout === -3) { authError.value = true; }
    sendPassUnlockTime.value = Long.fromNumber(new Date().getTime() + defaultUnlockTimeout);
  }
  passSent.value = true;
  wrongPass.value = false;
  await nextTick();
  if (codeInput.value !== null) {
    codeInput.value.focus();
  }
  setTimeout(() => { passSent.value = false; }, (jsTimeout > 0 ? (jsTimeout - new Date().getTime()) : defaultUnlockTimeout));
}

async function login () {
  if (!await rpcClient.loginByCode(passInput.value).catch(returnFalse)) wrongPass.value = true;
}

</script>
